import { mapState } from 'vuex';
import dayjs from 'dayjs';
import { Toast, Dialog } from 'vant';
import { eq } from 'lodash';
import { getDate, getStartedAt, getEndedAt } from '@/utils/common.js';
import { SHILISHOU_LEVEL_MAP, getRouteNameByLevel } from '@/constants/rebate';
import Rmb from '@/components/common/rmb/index.vue';
import { getUserInfoByDate, getRebateActivity, getRebateSystems } from '@/service/rebate/novNewRebate';

const defaultVal = '--';

/**
 * 通过比较路由名称以及查询参数是否相同判断是不是同一个路由对象
 * @param {*} routeName
 * @param {*} query
 */
function isSameRoute(currentRoute, routeName, query) {
    const { name, query: currQuery } = currentRoute;
    if (name !== routeName) {
        return false;
    } else {
        if (eq(currQuery, query)) {
            return true;
        }
    }

    return false;
}

export default {
    components: { Rmb },

    data() {
        return {
            currDate: getDate(this.$route.query.startTime),
            curActivityId: this.$route.query.activityId,
            curSystemId: this.$route.query.systemId,
            curActivity: {},
            curSystem: {},
            curActivityList: [],
            rebateInfo: {},
            defaultVal,
            agentId: this.$route.query.agentId,
            userInfoObj: null,
        };
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id,
        }),
        //  计算userInfo的等级名称
        userInfo() {
            const { defaultVal, userInfoObj } = this;
            const {
                id = defaultVal,
                name = defaultVal,
                headImg,
                systemId,
                levelId,
                type,
                levelName = defaultVal,
                systemName = defaultVal,
                levelCode,
            } = userInfoObj || {};
            // let levelName = SHILISHOU_LEVEL_MAP[levelId] || defaultVal;
            // if (String(type) === '1' && String(levelId) === '3') {
            //     levelName = '股东';
            // }
            // if (String(type) === '0' && String(levelId) === '3') {
            //     levelName = '联创';
            // }
            return {
                id,
                name,
                headImg,
                levelName,
                systemId,
                levelId,
                systemName,
                levelCode,
            };
        },
        startTime() {
            return getStartedAt(this.currDate);
        },
        endTime() {
            return getEndedAt(this.currDate);
        },
    },

    watch: {
        currDate: {
            immediate: true,
            handler: async function currDateHandler(newVal, oldVal) {
                const { agentId } = this;
                const { startTime, endTime } = this;
                this.reset();

                const systemList = (await getRebateSystems()) || [];
                let system = systemList.find(item => item.id == this.curSystemId);
                this.curSystem = { text: system.name, id: system.id };

                const activityList = (await getRebateActivity({ systemId: system.id })) || [];
                let activity = activityList.find(item => item.id == this.curActivityId);
                this.curActivity = { text: activity.title, id: activity.id };
                this.curActivityList = activityList.map(item => ({ text: item.title, id: item.id }));

                //  请求当前月份用户的授权信息
                const userInfoObj =
                    (await getUserInfoByDate({
                        userId: this.agentId,
                        startTime,
                        endTime,
                        systemId: system.id,
                    })) || {};
                this.userInfoObj = userInfoObj;

                if (this.userInfo.levelId) {
                    this.init();
                }

                if (userInfoObj.levelCode == 'QGZDL' || userInfoObj.levelCode == 'BJVIP') {
                    Toast(`等级为${userInfoObj.levelName},暂不支持业绩查看～`);
                    return;
                }

                if (dayjs(newVal).isBefore(dayjs('2020-11-01 00:00:00'))) {
                    let routeName = this.$route.name;
                    const levelName = SHILISHOU_LEVEL_MAP[userInfoObj.levelId] || '无授权';
                    routeName = getRouteNameByLevel(userInfoObj.levelId);

                    //  如果无授权或者无对应授权的页面提示并且提留在当前页面
                    if (!userInfoObj.levelId || userInfoObj.levelId === '-1' || !routeName) {
                        Toast(`选中的月份${startTime}的授权等级为${levelName},暂不支持业绩查看～`);
                        return;
                    }

                    if (!isSameRoute(this.$route, routeName, { agentId, startTime, endTime })) {
                        //  利用路由记录变化
                        this.$router.push({
                            name: routeName,
                            query: {
                                agentId: this.userId,
                                startTime,
                                endTime,
                                activityId: this.curActivityId,
                                systemId: this.curSystemId,
                            },
                        });
                    }
                }
            },
        },

        curSystem: {
            handler: async function currDateHandler(newVal, oldVal) {
                this.curSystemId = newVal.id;
                const userInfoObj =
                    (await getUserInfoByDate({
                        userId: this.agentId,
                        startTime: this.startTime,
                        endTime: this.endTime,
                        systemId: newVal.id,
                    })) || {};
                this.userInfoObj = userInfoObj;
                if (!userInfoObj.levelCode) {
                    if (Object.keys(oldVal).length !== 0) {
                        this.curSystem = oldVal;
                    }
                    Toast(`等级为无授权,暂不支持业绩查看～`);
                    return;
                }
                if (userInfoObj.levelCode == 'QGZDL' || userInfoObj.levelCode == 'BJVIP') {
                    if (Object.keys(oldVal).length !== 0) {
                        this.curSystem = oldVal;
                    }
                    Toast(`等级为${userInfoObj.levelName},暂不支持业绩查看～`);
                    return;
                }
                const activityList = (await getRebateActivity({ systemId: newVal.id })) || [];
                this.curActivityId = activityList.length === 0 ? '' : activityList[0].id;
                this.curActivity =
                    activityList.length === 0 ? {} : { text: activityList[0].title, id: activityList[0].id };
                this.curActivityList =
                    activityList.length === 0 ? [] : activityList.map(item => ({ text: item.title, id: item.id }));
            },
        },

        curActivity: {
            handler: async function currDateHandler(newVal, oldVal) {
                this.curActivityId = newVal.id;
                if (this.userInfo.levelId) {
                    this.init();
                }
            },
        },
    },

    methods: {
        init() {},

        reset() {
            this.rebateInfo = {};
        },

        //  点击折扣奖励发放管理
        clickRebateBtn() {
            this.$router.push({
                name: 'nov-new-rebate-purch-award',
                query: { ...this.$route.query },
            });
        },

        //  根据路由名称跳转到对应的页面
        onRebateDetail(routeName) {
            const { agentId, startTime, endTime, userInfo, curActivityId, curSystemId } = this;
            this.$router.push({
                name: routeName,
                query: { agentId, startTime, endTime, activityId: curActivityId, systemId: curSystemId },
            });
        },
    },

    created() {},
};
