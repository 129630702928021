import CompRebateLayoutOne from '@/components/rebate/comp-rebate-layout-one/index.vue';
import rebateMixin from '@/mixins/nov-new-rebate';
import { getTeamPurch } from '@/service/rebate/novNewRebate';
import RebateTable from '@/components/rebate/rebate-table/index.vue';

export default {
    name: 'nov-new-rebate-team-purch',
    mixins: [rebateMixin],
    components: {
        CompRebateLayoutOne,
        RebateTable,
    },

    data() {
        return {};
    },

    computed: {
        // 本人配置列
        selfColumns() {
            const { userInfo } = this;
            const columns = [
                {
                    label: '姓名',
                    key: 'name',
                },
                {
                    label: '团购金额',
                    isRmb: true,
                    key: 'personSale',
                },
                {
                    label: '销售折扣',
                    isRmb: true,
                    key: 'teamDiscount',
                },
                {
                    label: '固定配赠',
                    isRmb: true,
                    isNegative: true,
                    key: 'gift',
                },
            ];
            // 联创
            if (userInfo.levelId === '3') {
                columns.splice(3, 1);
            }

            return columns;
        },

        // 创始人表格配置列
        founderColumns() {
            const { userInfo } = this;
            const columns = [
                {
                    label: '创始人',
                    key: 'userName',
                },
                {
                    label: '团购金额',
                    isRmb: true,
                    key: 'totalSale',
                },
                {
                    label: '销售折扣',
                    isRmb: true,
                    key: 'teamDiscount',
                },
                {
                    label: '固定配赠',
                    isRmb: true,
                    isNegative: true,
                    key: 'gift',
                },
            ];
            // 联创
            if (userInfo.levelId === '3') {
                columns.splice(3, 1);
            }

            return columns;
        },

        // 合伙人表格配置列
        partnerColumns() {
            const { userInfo } = this;
            const columns = [
                {
                    label: '合伙人',
                    key: 'userName',
                },
                {
                    label: '团购金额',
                    isRmb: true,
                    key: 'totalSale',
                },
                {
                    label: '销售折扣',
                    isRmb: true,
                    key: 'teamDiscount',
                },
                {
                    label: '固定配赠',
                    isRmb: true,
                    isNegative: true,
                    key: 'gift',
                },
            ];
            // 联创
            if (userInfo.levelId === '3') {
                columns.splice(3, 1);
            }

            return columns;
        },

        //  代理本人团队团购页面
        selfDatasource() {
            const { rebateInfo, userInfo } = this;
            if (rebateInfo) {
                const { personSale, teamDiscount, gift } = rebateInfo;
                const { name, id: userId } = userInfo || {};
                return [{ personSale, teamDiscount, gift, name, userId }];
            } else {
                return [];
            }
        },

        //  创始人团队团购页面
        founderDatasource() {
            const { rebateInfo } = this;
            return rebateInfo.uniteList || [];
        },

        //合伙人团队团购明细
        partnerDatasource() {
            const { rebateInfo } = this;
            return rebateInfo.strategyList || [];
        },

        //  团队总人数
        account() {
            const { founderDatasource, partnerDatasource } = this;
            return founderDatasource.length + partnerDatasource.length;
        },
    },

    methods: {
        //  初始化页面
        init() {
            const { startTime, endTime, agentId, userInfo, curActivityId, curSystemId } = this;
            getTeamPurch({
                startTime,
                endTime,
                userId: agentId,
                systemId: curSystemId,
                levelId: userInfo.levelId,
                actId: curActivityId,
            }).then(data => {
                this.rebateInfo = data;
            });
        },

        showDetail(row) {
            // this.$router.push({
            //     name: "nov-new-rebate-team-purch-detail",
            //     query: { ...this.$route.query, agentId: row.userId },
            // });
        },

        changeSelectedActivity(value) {
            this.curActivity = value;
        },

        changeSelectedSystem(value) {
            this.curSystem = value;
        },
    },
};
